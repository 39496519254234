body{
    background-color: rgb(10, 10, 10);
    height: 100vh;  
    margin: 0px;
    overflow: hidden;
  }
 
 div {
    font-family: "Rubik", sans-serif ;
    font-weight: normal;
    font-style: normal;
 }