

// :root{
//     --background-color:rgb(6, 11, 25);
//     --border-color: rgb(255, 255, 255);
// }


  body {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0px;
    overflow: hidden;
  }
*{
    box-sizing: border-box;
}
h1,h2,h3,a,p,span{
        font-weight: 400;
        color:white;

    }
    a{
        text-decoration: none;
    }
 .container-3 {
    
    position: absolute;



 background-color:var(--background-color);
    top: 0%;
    left:0%;
    width: 100vw;
    height: 100vh;
    margin: 2px;
z-index:0;

    transform: translatex(0%);


// added from tut

display: flex;
flex-direction: column;


  }

  

//   nav {
//     display: flex ;
//     width: 100%;
//     border-bottom: 1px solid var(--border-color);
//   }


//   nav > .nav-section{
//     align-items: center;
//     justify-content: center;
//     padding:2rem 2rem ;
//     display: flex;
//     gap: 1rem;
//     border-left: 1px solid var(--border-color);
//   }
//   #nav-logo-section,
//   #nav-link-section{
//     flex-basis: calc(100%/3);
//   }
//   #nav-social-section,
//   #nav-contact-section{
//     flex-grow: 1;
//   }


//   #nav-logo-section{
//     justify-content: flex-start;
//   }
//   #nav-logo-section > a > i {
//  font-size: 2.5rem;
//   }


//   #nav-link-section{
//     gap:6rem;
//   }
//   #nav-social-section{
//     gap:3rem;
//   }

//   main{
//     flex-grow: 1;
//   }
//   main > article{
// display: grid;
// height: 100%;
// grid-template-columns: 2fr 1fr;
// grid-template-rows: 2fr 1fr;

//   }

//   main > article > .article-section{

//     height: 100%;
//     border: 1px solid var(--border-color);
//       }

//   main > article > .article-description-section,
//   main > article > .article-nav-section {
//     border-left: none;
//   }

//   main > article > .article-image-section {
//     order: 1;
//   }
  
//   main > article > .article-title-section {
//     border-bottom: 1px solid var(--border-color);
//     order: 2;
//   }
  
//   main > article > .article-description-section {
//     justify-content: center;
//     order: 3;
//   }
  
//   main > article > .article-nav-section {
//     border-bottom: 1px solid var(--border-color);
//     order: 4;
//   }



:root {
    --background-color: rgb(0, 0, 0);
    --border-color: rgb(255, 255, 255, 0.1);
    --highlight-color: rgb(234, 0, 255);
    --yt-color: rgb(229, 57, 53);
}
  
  * {
    box-sizing: border-box;
  }
  
  h1, h2, h3, a, p, span {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    color: white;
    margin: 0px;
  }
  
  a {
    text-decoration: none;
  }
  
  a:hover {
    color: var(--highlight-color);
  }
  
  nav {

    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
  }
  
  nav .nav-section {
    padding: 3rem 2rem;
    display: flex;
    gap: 1rem;
    border-left: 1px solid var(--border-color);
    align-items: center;
    justify-content: center;
  }
  
  #nav-mobile-section {
    display: flex;
    flex-basis: calc(100% * (2 / 3));
    z-index: 1000;
  }
  
  #nav-toggle-button1 { 
    align-items: center;
    background-color: transparent;
    border: none;
    border-left: 1px solid var(--border-color);
    color: white;
    cursor: pointer;
    display: none;
    gap: 0.8rem;
    height: 100%;
    justify-content: center;
    outline: none;
    padding: 0rem 3rem;
    position: relative;
    z-index: 1000;
  }
  
  #nav-toggle-button1:hover,
  #nav-toggle-button1:hover > span {
    color: var(--highlight-color);
  }
  
  #nav-toggle-button1 > span,
  #nav-toggle-button1 > i { 
    display: inline-block;
    height: 1rem;
    line-height: 1rem;
  }
  
  #nav-social-section,
  #nav-contact-section {
    flex-grow: 1;
  }
  
  #nav-logo-section {
    flex-basis: calc(100% / 3);
    justify-content: flex-start;
  }
  
  #nav-logo-section > a > i {
    font-size: 2.5rem;
  }
  
  #nav-link-section {
    flex-basis: 50%;
    gap: 6rem; 
  }
  
  #nav-social-section {
    gap: 3rem;
  }
  
  main {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }
  
  main > article {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 2fr 1fr;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: transform 400ms ease;  
  }
  
  main > article[data-status="inactive"] {
    transform: translateX(-100%);
    transition: none;
  }
  
  main > article[data-status="active"] {
    transform: translateX(0%);
  }
  
  main > article[data-status="before"] {
    transform: translateX(-100%);
  }
  
  main > article[data-status="after"] {
    transform: translateX(100%);
  }
  
  main > article[data-status="becoming-active-from-before"] {
    transform: translateX(-100%);
    transition: none;
  }
  
  main > article[data-status="becoming-active-from-after"] {
    transform: translateX(100%);
    transition: none;
  }
  
  main > article > .article-section {
    height: 100%;
    display: flex;
  }
  
  main > article > .article-description-section,
  main > article > .article-nav-section {
    border-left: 1px solid var(--border-color);
  }
  
  main > article > .article-title-section,
  main > article > .article-nav-section {
    border-top: 1px solid var(--border-color);
  }
  
//   main > article:first-child > .article-image-section {
//     background-image: url("https://images.unsplash.com/photo-1660766877755-4cac24f6cf21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2232&q=80");
//   }
  
//   main > article:nth-child(2) > .article-image-section {
//     background-image: url("https://images.unsplash.com/photo-1660766877755-4cac24f6cf21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2232&q=80");
//   }
  
//   main > article:nth-child(3) > .article-image-section {
//     background-image: url("https://images.unsplash.com/photo-1660766877755-4cac24f6cf21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2232&q=80");
//   }
  
//   main > article:nth-child(4) > .article-image-section {
//     background-image: url("https://images.unsplash.com/photo-1660766877755-4cac24f6cf21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2232&q=80");
//   }
  
  main > article > .article-image-section {
    background-position: center;
    background-size: cover;
  }
  
  main > article > .article-description-section {
    flex-direction: column;
    justify-content: center;
    padding: 4rem; 
  }
  
  main > article > .article-description-section > a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.25em;

  }
  
  main > article > .article-description-section   > a:hover {
    text-decoration: underline;


  }
  
  main > article > .article-title-section {
    align-items: center; 
    justify-content: space-between;
    padding: 2rem 4rem;
  }
  
  main > article > .article-title-section * {
    color: white;
  }
  
  main > article > .article-title-section > h2 {
    flex-basis: 50%;

    font-size: 3.5rem;
    line-height: 3rem;
    margin: 0px;
    text-transform: uppercase;
  }
  
  main > article > .article-title-section > i {
    color: rgba(255, 255, 255, 0.75);
    font-size:  4rem;
  }
  
  main > article > .article-nav-section > .article-nav-button {
    background-color: rgba(255, 255, 255, 0);
    flex-grow: 1;
    border: none;
    outline: none;
    color: rgba(255, 255, 255, 0.75);
    font-size: 3rem;
    cursor: pointer;
  }
  
  main > article > .article-nav-section > .article-nav-button:hover {
    background-color: rgba(255, 255, 255, 0.02);
  }
  
  main > article > .article-nav-section > .article-nav-button:nth-child(2) {
    border-left: 1px solid var(--border-color);
  }
  
  @media(max-width: 1200px) {    
    #nav-mobile-section {
      flex-basis: calc(100% * 0.75);
      
    }
  
    #nav-logo-section {
       
      flex-basis: calc(100% * 0.25);
    }
    
    #nav-link-section {
      flex-basis: calc(100% / 3);
      gap: 3rem;
    }
  
    main {
      overflow-y: auto;
    }
    
    main > article {
      grid-template-columns: none;
      grid-template-rows: 1.5fr repeat(3, 0.75fr) 0.25fr;
      height: max(900px, 100%);
    }
     
    main > article > .article-title-section > h2 {
      font-size: 3rem; 
      line-height: 2.6rem;
    }
    
    main > article > .article-title-section > i {
      font-size: 3rem; 
    }
    
    main > article > .article-description-section,
    main > article > .article-nav-section {
      border-left: none;
    }
  
    main > article > .article-image-section {
      order: 1;
    }
    
    main > article > .article-title-section {
      border-bottom: 1px solid var(--border-color);
      order: 2;
    }
    
    main > article > .article-description-section {
      justify-content: center;
      order: 3;
    }
    
    main > article > .article-nav-section {
      border-bottom: 1px solid var(--border-color);
      order: 4;
    }
  }
  
  @media(max-width: 800px) {  
    nav {
      justify-content: space-between;  
    }
    
    nav[data-toggled="true"] > #nav-mobile-section {  
      transform: translateY(0%);
    }
  
    nav[data-toggled="true"] > #nav-toggle-button1 {
      border-left: none;
    }
  
    nav[data-transitionable="true"] > #nav-mobile-section {
      transition: transform 400ms ease;
    }
    
    nav .nav-section {
        
      border-left: none;
      padding: 1.5rem 1rem;
    }
    
    #nav-logo-section > a > i {
      font-size: 1.5rem;
      margin-left: 1rem;
    }
    
    #nav-mobile-section {
      background-color: var(--background-color);
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      position: fixed;
      transform: translateY(-100%);
    } 
    
    #nav-toggle-button1 {
    
      display: flex;
    }  
    
    #nav-link-section {
      flex-basis: 60%;
      flex-direction: column;
      gap: 4rem;
    }
    
    #nav-link-section > a {
      font-size: 3rem; 
    }
    
    #nav-social-section > a {
      font-size: 2rem; 
    }  
    
    #nav-contact-section {
      padding-bottom: 4rem;
    }
    
    main > article {
      grid-template-rows: 1fr repeat(4, 0.5fr);
      height: max(700px, 100%);
    }
    
    main > article > .article-title-section {
      padding: 2rem;
    }
    
    main > article > .article-title-section > h2 {
      flex-basis: 70%;
      font-size: 1.75em; 
      line-height: 1.5rem;
    }
    
    main > article > .article-title-section > i {
      font-size: 2rem; 
    }
    
    main > article > .article-description-section {
      padding: 2rem;
    }
    
    main > article > .article-description-section > p {
      font-size: 1rem;
    }
  }
  
#mycustomhover{
    
    animation: myfirst 8s linear 0s infinite alternate;
}

  @keyframes myfirst {
    0%   {transform: scale(1);
   top:0px;}
    25%  {transform: scale(0.9);top:20px;}
    50%  { transform: scale(1.0);top:0px;}
    75%  { transform: scale(0.9);top:20px;}
    100% {transform: scale(1);top:0px;}
  }