
  
//   .container-1 {
//     position: absolute;
//     transform: translate(-5%, -50%);
//     top: 50%;
//     left: 50%;
//     overflow: hidden;
//     z-index: -1;
//   }
  
//   .glitch {
//     color: rgb(223, 191, 191);
//     position: relative;
//     font-size: 9vw;
//     // margin: 70px 200px;
//     animation: glitch 5s 5s infinite;
//   }
  
//   .glitch::before {
//     content: attr(data-text);
//     position: absolute;
//     left: -2px;
//     text-shadow: -5px 0 magenta;
 
//     overflow: hidden;
//     top: 0;
//     animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
//   }
  
//   .glitch::after {
//     content: attr(data-text);
//     position: absolute;
//     left: 2px;
//     text-shadow: -5px 0 lightgreen;

//     overflow: hidden;
//     top: 0;
//     animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
//   }
  
//   @keyframes glitch {
//     1%{
//       transform: rotateX(10deg) skewX(90deg);
//     }
//     2%{
//       transform: rotateX(0deg) skewX(0deg);
//     }
//   }
  
//   @keyframes noise-1 {
//     $steps: 30;
//     @for $i from 1 through $steps {
//       #{percentage($i*(1/$steps))} {
//         $top: random(100);
//         $bottom: random(101 - $top);
//         clip-path: inset(#{$top}px 0 #{$bottom}px 0);
//       }
//     }
//   }
  
//   @keyframes noise-2 {
//     $steps: 30;
//     @for $i from 0 through $steps {
//       #{percentage($i*(1/$steps))} {
//         $top: random(100);
//         $bottom: random(101 - $top);
//         clip-path: inset(#{$top}px 0 #{$bottom}px 0);
//       }
//     }
//   }
  
  
  
//   .glow {
//     @extend .glitch;
//     text-shadow: 0 0 1000px rgb(195, 36, 36);
//     color: transparent;
//     position: absolute;
//     top: 0;
//   }
  
//   .subtitle {
//     font-family: Arial, Helvetica, sans-serif;
//     font-weight: 100;
//     font-size: .8vw;
//     color: rgba(165, 141, 141, .4);
//     text-transform: uppercase;
//     letter-spacing: 1em;
//     text-align: center;
//     position: absolute;
//     left: 10%;
//     top:80%;
//     animation: glitch-2 5s 5.02s infinite;
//   }
  
//   @keyframes glitch-2 {
//     1%{
//       transform: rotateX(10deg) skewX(70deg);
//     }
//     2%{
//       transform: rotateX(0deg) skewX(0deg);
//     }
//   }
  

@mixin reverse-neu {
    box-shadow: inset -5px -5px 15px 0px #ffffff9e,
      inset 5px 5px 15px 0px #a3b1c6a8;
  }



  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1em 0;
  }
  


.neu {
  box-shadow: -5px -5px 15px 0px #ffffff9e, 5px 5px 15px 0px #a3b1c6a8;
  background: #e0e5ec;
  border-radius: 2em;
  border: 0;
}

.btn {
  padding: 15px;
  border-radius: 30px;
  color: #7e8a98;
  outline: none;
  cursor: pointer;
  &.lg {
    font-size: 1em;
  }
  &:active {
    cursor: pointer;
    @include reverse-neu;
  }
}

.red {
  color: red;
}
  
